$(document).ready(function (e) {
  window.ytPlayers = []
  var $videos = $('.m_video-container')
  $videos.each(function (i, video) {
    m_video_container__init($(video))
  })
})

function m_video_container__init (video) {
  var $prealoader = video.find('.preload-image')
  var videoCode = $prealoader.attr('data-video-src')
  var originUrl = window.location.protocol + '//' + window.location.host
  $prealoader.on('click', function () {
    // Create the iframe and YT Player object. Add it to the array
    var $videoIframe = $(
      '<iframe class="youtube-video" src="' +
        videoCode +
        '?rel=0&autoplay=1&enablejsapi=1&origin=' +
        originUrl +
        '" frameborder="0" allowfullscreen></iframe>'
    )
    video.append($videoIframe)
    window.ytPlayers.push(new YT.Player($videoIframe[0]))
  })
}

function m_video_container__pauseAll () {
  window.ytPlayers.forEach(function (ytPlayer, index, array) {
    ytPlayer.pauseVideo()
  })
}
