$(document).ready(function (e) {
  var $slideshows = $('.m_slideshow__full')
  if ($slideshows.length > 0) {
    $slideshows.each(function (i, slideshow) {
      m_slideshow__full__init($(slideshow))
    })
    m_slideshow__lazyload($slideshows)
  }

  var $slideshows2 = $('.m_slideshow__clean')
  if ($slideshows2.length > 0) {
    $slideshows2.each(function (i, slideshow) {
      m_slideshow__clean__init($(slideshow))
    })
  }

  var $slideshowsBook = $('.m_slideshow__book')
  if ($slideshowsBook.length > 0) {
    $slideshowsBook.each(function (i, slideshow) {
      m_slideshow__book__init($(slideshow))
    })
  }
  // setTimeout(() => {
  //   $(window).trigger('resize')
  // }, 100);

  // setTimeout(() => {
  //   $(window).trigger('resize')
  // }, 600);
})

function m_slideshow__full__init (slideshow) {
  slideshow.slick({
    infinite: true,
    dots: true,
    arrows: true,
    adaptiveHeight: true
  })

  slideshow.on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      m_video_container__pauseAll()
    }
  )
}

function m_slideshow__clean__init (slideshow) {
  slideshow.slick({
    infinite: true,
    dots: false,
    arrows: true,
    adaptiveHeight: false
  })
}

function m_slideshow__book__init (slideshow) {
  slideshow.slick({
    infinite: false,
    dots: true,
    arrows: true,
    adaptiveHeight: true
  })
}

function m_slideshow__lazyload (slideshows) {
  slideshows.find('.m_slide').each(function () {
    var $imageTag = $(this).find('img[data-src]')
    $imageTag.attr('src', '/assets/imgs/placeholder-fullwidth.gif')
  })

  // Lazy loading - Based on http://developer.telerik.com/featured/lazy-loading-images-on-the-web/
  $(window).on('DOMContentLoaded load resize scroll', function () {
    m_slideshow__updateLazyLoading(slideshows)
  })
}

function m_slideshow__updateLazyLoading (slideshows) {
  var images = slideshows.find('img[data-src]')
  // load images that have entered the viewport
  $(images).each(function (index) {
    if (m_slideshow__isElementInViewport(this)) {
      $(this).attr('src', $(this).attr('data-src'))
      $(this).removeAttr('data-src')
      $(this).attr('srcset', $(this).attr('data-srcset'))
      $(this).removeAttr('data-srcset')
    }
  })
}

// source: http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
function m_slideshow__isElementInViewport (el) {
  var rect = el.getBoundingClientRect()
  return (
    // only cares aboout top and bottom
    rect.bottom >= 0 && rect.top <= $(window).height()
  )
}
